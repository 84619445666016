import React from "react"
import { Link } from "gatsby"
import "../styles/femmag.css"

const FemmagPage = () => {
    return (

        <div className="page-container-femmag">
            <section className="page-femmag">
            <div className="banner-side-femmag"></div>
                <div className="femmag-content">
                    <div className="femmag-image-container">
                        <div className="femmag-border"></div>
                        <img className="femmag-image" src="/femmag/images/fem.mag-logo.png" alt="Schriftzug femmag feministisches Magazin online"/>
                        <img className="hand" src="/femmag/images/hand.png" alt="Hand die drei Finger zeigt"/>

                    </div>
                    <p className="text-femmag">
                        Die Ausstellung von fem.mag findet im Rahmen des Festivals Nocturnal Unrest statt und kann auch im Hafen2 in Offenbach zu den Flanier-Café Öffnungszeiten vor Ort angeschaut werden. Mehr zum Programm von Nocturnal Unrest findet ihr, wenn ihr &nbsp; 
                        <Link to="/programm" className="link-femmag">diesem Link zur Programmseite folgt</Link>.
                    </p>
                    <p className="text-femmag abstand-femmag">
                    The exhibition by fem.mag is part of the Nocturnal Unrest festival and can also be viewed at Hafen2 in Offenbach during the opening hours of the Flanier-Café. You can find out more about the Nocturnal Unrest programme by following &nbsp; 
                        <Link to="/programm" className="link-femmag">this link to the programme page</Link>.
                    </p>
                    <div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag1.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.1.jpg" alt={"PDF fem.mag Ausgabe 1"}/>
                                    <p className="project-name-femmag">Feminismus für 9.99 EURO</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <img className="project-pic-femmag" src="/femmag/images/pic1.jpg" alt="Zwei vermummte Personen hocken vor einer Wand voller Wandzeitungen."/>
                                <p className="project-name-femmag"></p>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag2.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.2.jpg" alt={"PDF fem.mag Ausgabe 2"}/>
                                    <p className="project-name-femmag">Frauen*streik</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag3.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.3.jpg" alt={"PDF fem.mag Ausgabe 3"}/>
                                    <p className="project-name-femmag">Antifeminismus in rechten Diskursen</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/wer-wir-sind.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/wer-sind-wir.jpg" alt="PDF wer sind wir"/>
                                    <p className="project-name-femmag"></p>
                                </a>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag4.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.4.jpg" alt={"PDF fem.mag Ausgabe 4"}/>
                                    <p className="project-name-femmag">Gegen Rassismus, Antisemitismus und Faschismus</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag5.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.5.jpg" alt={"PDF fem.mag Ausgabe 5"}/>
                                    <p className="project-name-femmag">Zeitstrahl: Der Begriff Feminizid</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <img className="project-pic-femmag" src="/femmag/images/pic2.jpg" alt="Eine Wandzeitung des Fem.Mags. auf einem grauen Hintergrund."/>
                                <p className="project-name-femmag"></p>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag6+7.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.6+7.jpg" alt={"PDF fem.mag Ausgabe 6+7"}/>
                                    <p className="project-name-femmag">Formen der Gewalt erkennen</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag8.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.8.jpg" alt={"PDF fem.mag Ausgabe 8"}/>
                                    <p className="project-name-femmag">Widerstand!</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <a href="https://www.youtube.com/watch?v=cVPHFIiosI8" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/bock-mit-zu-machen.jpg" alt="Youtube Video fem.mag"/>
                                    <p className="project-name-femmag"></p>
                                </a>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag9.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.9.jpg" alt={"PDF fem.mag Ausgabe 9"}/>
                                    <p className="project-name-femmag">Feminizid</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag10.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.10.jpg" alt={"PDF fem.mag Ausgabe 10"}/>
                                    <p className="project-name-femmag">Tag gegen patriarchale Gewalt</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <img className="project-pic-femmag" src="/femmag/images/pic3.jpg" alt="Auf einem Stromverteiler-Kasten mit Graffiti kleben drei Wandzeitungen des Fem.Mag."/>
                                <p className="project-name-femmag"></p>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag11.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.11.jpg" alt={"PDF fem.mag Ausgabe 11"}/>
                                    <p className="project-name-femmag">Digitale Gewalt gegen FLINTA*</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag12+13.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.12+13.jpg" alt={"PDF fem.mag Ausgabe 12+13"}/>
                                    <p className="project-name-femmag">Was passiert am 08. März?</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/hafen2.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/freie-wildbahn.jpg" alt="Schriftzug fem.mag in freier Wildbahn"/>
                                    <p className="project-name-femmag"></p>
                                </a>
                                
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag14+15.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-border" src="/femmag/images/mag-nr.14+15.jpg" alt={"PDF fem.mag Ausgabe 14+15"}/>
                                    <p className="project-name-femmag">Haitianische Revolution - Die verdrängte Revolution</p>
                                </a>
                            </div>
                        </div>
                        <div className="project-container-femmag">
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag16.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.16.jpg" alt={"PDF fem.mag Ausgabe 16"}/>
                                    <p className="project-name-femmag">Reproduktive Gerechtigkeit</p>
                                </a>
                            </div>
                            <div className="project-third">
                                <img className="project-pic-femmag" src="/femmag/images/pic4.jpg" alt="Drei Fem.Mag. Wandzeitungen auf einem spiegelnden Hintergrund. Im Hintergrund erkennt eine:r Häuser."/>
                                <p className="project-name-femmag"></p>
                            </div>
                            <div className="project-third">
                                <a href="/femmag/PDF/fem.mag17+18.pdf" className="link-project" target="_blank" rel="noopener noreferrer">
                                    <img className="project-pic-femmag" src="/femmag/images/mag-nr.17+18.jpg" alt={"PDF fem.mag Ausgabe 17+18"}/>
                                    <p className="project-name-femmag">Antifeminismus und rechter Terror</p>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default FemmagPage